import React from "react";
import "./Contact.css";

function Contact() {
	return (
		<div id="contact" class="contact-container">
			<div className="contact-body">
				<p>1320 Church Street</p>
				<p>San Francisco, CA</p>
				<p>
					<a className="email-link" href="mailto:info@inputcap.com">
						info@inputcap.com
					</a>
				</p>
			</div>
		</div>
	);
}

export default Contact;
