import React from "react";
import "./About.css";

function About() {
	return (
		<div id="about" class="about-container">
			<div class="about-body">
				<h1>About</h1>
				<p className="p-1">
					From seed to venture to high growth, Input Capital works with founders
					and visionaries across every stage and sector.
				</p>
				<p className="p-2">
					As founders ourselves, we take a hands on approach to help
					entrepeneurs build generational companies.
				</p>
			</div>
			<div className="down-arrow down-arrow-spc">
				<a href="#portfolio">
					<svg viewBox="0 0 1866 338">
						<path
							d="M10.656,0.748L934,304,1857,2l9,29L933.813,337.066,0.766,30.886Z"
							fill="#000"
						/>
					</svg>
				</a>
			</div>
		</div>
	);
}

export default About;
