import React from "react";
import "./Title.css";
import InputLogo from "./images/input-cap-logo-white.png";

function Title() {
	return (
		<div id="title" class="title-container">
			<div class="header">
				<div className="logo-container">
					{/* <img className="input-arrow" src={InputArrow} alt="" /> */}
					{/* <span className="logo-name">INPUT CAPITAL</span> */}
					<img className="input-logo" src={InputLogo} alt="" />
				</div>
				<div className="header-menu">
					<a href="#about" className="menu-item">
						About
					</a>
					<span className="slash">/</span>
					<a href="#portfolio" className="menu-item">
						Portfolio
					</a>
					<span className="slash">/</span>
					<a href="#team" className="menu-item">
						Team
					</a>
				</div>
			</div>
			<div className="down-arrow down-arrow-spc">
				<a href="#about">
					<svg viewBox="0 0 1866 338">
						<path
							d="M10.656,0.748L934,304,1857,2l9,29L933.813,337.066,0.766,30.886Z"
							fill="#fff"
						/>
					</svg>
				</a>
			</div>
		</div>
	);
}

export default Title;
