import React from "react";
import "./Team.css";
import One from "./images/1.png";
import Two from "./images/2.png";
import Three from "./images/3.png";

function Team() {
	return (
		<div id="team" className="team-container">
			<div className="team-body">
				<h1>Team</h1>
				<div className="row">
					<ul>
						<li>
							<img src={Two} alt="" className="talati"></img>
							<a href="https://www.linkedin.com/in/rishitalati/">
								<h2>Rishi Talati</h2>
							</a>
							<p>
								Rishi brings expertise in early stage startups. As the
								Co-Founder and COO of{" "}
								<a className="hyperlink" href="https://www.hotplate.com/">
									Hotplate
								</a>
								, Rishi conceptualized the idea and raised funding from Wave
								Capital and Y Combinator.
							</p>
						</li>
						<li>
							<img src={One} alt="" className="talati"></img>
							<a href="https://www.linkedin.com/in/sunil-talati-58272616/">
								<h2>Sunil Talati</h2>
							</a>
							<p>
								Founder & CEO of{" "}
								<a className="hyperlink" href="https://eeplus.com/">
									EEPlus
								</a>
								. Over 30 years of corporate leadership experience in project
								management, business development, engineering design of
								electrical systems, and power system analysis.
							</p>
						</li>

						<li>
							<img src={Three} alt="" className="talati"></img>
							<a href="https://www.linkedin.com/in/rahul-talati-1985b114/">
								<h2>Rahul Talati</h2>
							</a>
							<p>
								Rahul brings a blend of deep marketing and operational
								experience. Rahul spent 10 years at Google and LinkedIn in
								operational roles, and currently serves as a VP of Strategy and
								Operations at{" "}
								<a className="hyperlink" href="https://www.salesforce.com/">
									Salesforce
								</a>
								.
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className="down-arrow">
				<a href="#contact">
					<svg viewBox="0 0 1866 338">
						<path
							d="M10.656,0.748L934,304,1857,2l9,29L933.813,337.066,0.766,30.886Z"
							fill="#000"
						/>
					</svg>
				</a>
			</div>
		</div>
	);
}

export default Team;
