import React from "react";
import "./Portfolio.css";
import AlchemyLogo from "./images/bw2/alchemy.png";
import AlchemyLogo2 from "./images/color/alchemy.png";

import HighlightLogo from "./images/bw2/highlight.png";
import HighlightLogo2 from "./images/color/highlight.png";

import KyteLogo from "./images/bw2/kyte.png";
import KyteLogo2 from "./images/color/kyte.png";

import MystenLabsLogo from "./images/bw2/mysten.png";
import MystenLabsLogo2 from "./images/color/mysten.png";

import RailwayLogo from "./images/bw2/railway.png";
import RailwayLogo2 from "./images/color/railway.png";

import SolugenLogo from "./images/bw2/solugen.png";
import SolugenLogo2 from "./images/color/solugen.png";

import StonksLogo from "./images/bw2/stonks.png";
import StonksLogo2 from "./images/color/stonks.png";

import FynnLogo from "./images/bw2/fynn.png";
import FynnLogo2 from "./images/color/fynn.png";

import AtomicLogo from "./images/bw2/atomic.png";
import AtomicLogo2 from "./images/color/atomic.png";

import HotplateLogo from "./images/bw2/hotplate.png";
import HotplateLogo2 from "./images/color/hotplate.png";

import OpenPhoneLogo from "./images/bw2/openphone.png";
import OpenPhoneLogo2 from "./images/color/openphone.png";

import AirplaneLogo from "./images/bw2/airplane.png";
import AirplaneLogo2 from "./images/color/airplane.png";

import RasaLogo from "./images/bw2/rasa.png";
import RasaLogo2 from "./images/color/rasa.png";

import ConsensysLogo from "./images/bw2/consensys.png";
import ConsensysLogo2 from "./images/color/consensys.png";

import ReplitLogo from "./images/bw2/replit.png";
import ReplitLogo2 from "./images/color/replit.png";

import FrontLogo from "./images/bw2/front.png";
import FrontLogo2 from "./images/color/front.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Portfolio() {
	return (
		<div id="portfolio" class="portfolio-container">
			<div className="portfolio-body">
				<h1>Portfolio</h1>
				<div className="carousel-div-desktop">
					<Carousel showStatus={false} autoPlay={false} infiniteLoop={false}>
						<div>
							<ul className="carousel-ul">
								<li className="logo-link-1">
									<a href="https://www.atomicvest.com/">
										<img className="logo-img" src={AtomicLogo} alt=""></img>
										<img className="logo-img" src={AtomicLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-2">
									<a href="https://hotplate.com/">
										<img className="logo-img" src={HotplateLogo} alt=""></img>
										<img className="logo-img" src={HotplateLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-3">
									<a href="https://fynncredit.com/">
										<img className="logo-img" src={FynnLogo} alt=""></img>
										<img className="logo-img" src={FynnLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-4">
									<a href="https://solugen.com/">
										<img className="logo-img" src={SolugenLogo} alt=""></img>
										<img className="logo-img" src={SolugenLogo2} alt=""></img>
									</a>
								</li>

								<li className="logo-link-5">
									<a href="https://stonks.com/">
										<img
											className="logo-img-stonks"
											src={StonksLogo}
											alt=""
										></img>
										<img
											className="logo-img-stonks"
											src={StonksLogo2}
											alt=""
										></img>
									</a>
								</li>
								<li className="logo-link-6">
									<a href="https://drivekyte.com/">
										<img className="logo-img-kyte" src={KyteLogo} alt=""></img>
										<img className="logo-img-kyte" src={KyteLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-7">
									<a href="http://alchemy.com/">
										<img className="logo-img" src={AlchemyLogo} alt=""></img>
										<img className="logo-img" src={AlchemyLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-8">
									<a href="http://openphone.com/">
										<img
											className="logo-img-spc"
											src={OpenPhoneLogo}
											alt=""
										></img>
										<img
											className="logo-img-spc"
											src={OpenPhoneLogo2}
											alt=""
										></img>
									</a>
								</li>
							</ul>
						</div>
						<div>
							<ul className="carousel-ul">
								<li className="logo-link-9">
									<a href="http://airplane.dev/">
										<img className="logo-img" src={AirplaneLogo} alt=""></img>
										<img className="logo-img" src={AirplaneLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-10">
									<a href="http://rasa.com/">
										<img className="logo-img" src={RasaLogo} alt=""></img>
										<img className="logo-img" src={RasaLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-11">
									<a href="http://consensys.com/">
										<img className="logo-img" src={ConsensysLogo} alt=""></img>
										<img className="logo-img" src={ConsensysLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-12">
									<a href="http://replit.com/">
										<img className="logo-img" src={ReplitLogo} alt=""></img>
										<img className="logo-img" src={ReplitLogo2} alt=""></img>
									</a>
								</li>

								<li className="logo-link-13">
									<a class="railwaycontainer" href="https://railway.app/">
										<img className="logo-img" src={RailwayLogo} alt=""></img>
										<img className="logo-img" src={RailwayLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-14">
									<a href="https://www.highlight.io/">
										<img className="logo-img" src={HighlightLogo} alt=""></img>
										<img className="logo-img" src={HighlightLogo2} alt=""></img>
									</a>
								</li>

								<li className="logo-link-15">
									<a href="http://front.com/">
										<img className="logo-img" src={FrontLogo} alt=""></img>
										<img className="logo-img" src={FrontLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-16">
									<a href="https://mystenlabs.com/">
										<img
											className="logo-img-spc"
											src={MystenLabsLogo}
											alt=""
										></img>
										<img
											className="logo-img-spc"
											src={MystenLabsLogo2}
											alt=""
										></img>
									</a>
								</li>
							</ul>
						</div>
					</Carousel>
				</div>
				<div className="carousel-div-mobile">
					<Carousel showStatus={false} autoPlay={false} infiniteLoop={false}>
						<div className="carousel-div">
							<ul className="carousel-ul">
								<li className="logo-link-1">
									<a href="https://www.atomicvest.com/">
										<img className="logo-img" src={AtomicLogo} alt=""></img>
										<img className="logo-img" src={AtomicLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-2">
									<a href="https://hotplate.com/">
										<img className="logo-img" src={HotplateLogo} alt=""></img>
										<img className="logo-img" src={HotplateLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-3">
									<a href="https://fynncredit.com/">
										<img className="logo-img" src={FynnLogo} alt=""></img>
										<img className="logo-img" src={FynnLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-4">
									<a href="https://solugen.com/">
										<img className="logo-img" src={SolugenLogo} alt=""></img>
										<img className="logo-img" src={SolugenLogo2} alt=""></img>
									</a>
								</li>
							</ul>
						</div>
						<div className="carousel-div">
							<ul className="carousel-ul">
								<li className="logo-link-5">
									<a href="https://stonks.com/">
										<img
											className="logo-img-stonks"
											src={StonksLogo}
											alt=""
										></img>
										<img
											className="logo-img-stonks"
											src={StonksLogo2}
											alt=""
										></img>
									</a>
								</li>
								<li className="logo-link-6">
									<a href="https://drivekyte.com/">
										<img className="logo-img" src={KyteLogo} alt=""></img>
										<img className="logo-img" src={KyteLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-7">
									<a href="http://alchemy.com/">
										<img className="logo-img" src={AlchemyLogo} alt=""></img>
										<img className="logo-img" src={AlchemyLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-8">
									<a href="http://openphone.com/">
										<img
											className="logo-img-spc"
											src={OpenPhoneLogo}
											alt=""
										></img>
										<img
											className="logo-img-spc"
											src={OpenPhoneLogo2}
											alt=""
										></img>
									</a>
								</li>
							</ul>
						</div>
						<div className="carousel-div">
							<ul className="carousel-ul">
								<li className="logo-link-9">
									<a href="http://airplane.dev/">
										<img className="logo-img" src={AirplaneLogo} alt=""></img>
										<img className="logo-img" src={AirplaneLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-10">
									<a href="http://rasa.com/">
										<img className="logo-img" src={RasaLogo} alt=""></img>
										<img className="logo-img" src={RasaLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-11">
									<a href="http://consensys.com/">
										<img className="logo-img" src={ConsensysLogo} alt=""></img>
										<img className="logo-img" src={ConsensysLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-12">
									<a href="http://replit.com/">
										<img className="logo-img" src={ReplitLogo} alt=""></img>
										<img className="logo-img" src={ReplitLogo2} alt=""></img>
									</a>
								</li>
							</ul>
						</div>
						<div className="carousel-div">
							<ul className="carousel-ul">
								<li className="logo-link-13">
									<a class="railwaycontainer" href="https://railway.app/">
										<img className="logo-img" src={RailwayLogo} alt=""></img>
										<img className="logo-img" src={RailwayLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-14">
									<a href="https://www.highlight.io/">
										<img className="logo-img" src={HighlightLogo} alt=""></img>
										<img className="logo-img" src={HighlightLogo2} alt=""></img>
									</a>
								</li>

								<li className="logo-link-15">
									<a href="http://front.com/">
										<img className="logo-img" src={FrontLogo} alt=""></img>
										<img className="logo-img" src={FrontLogo2} alt=""></img>
									</a>
								</li>
								<li className="logo-link-16">
									<a href="https://mystenlabs.com/">
										<img
											className="logo-img-spc"
											src={MystenLabsLogo}
											alt=""
										></img>
										<img
											className="logo-img-spc"
											src={MystenLabsLogo2}
											alt=""
										></img>
									</a>
								</li>
							</ul>
						</div>
					</Carousel>
				</div>
			</div>
			<div className="down-arrow down-arrow-spc">
				<a href="#team">
					<svg viewBox="0 0 1866 338">
						<path d="M10.656,0.748L934,304,1857,2l9,29L933.813,337.066,0.766,30.886Z" />
					</svg>
				</a>
			</div>
		</div>
	);
}

export default Portfolio;
