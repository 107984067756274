import React from "react";
import "../../App.css";
import Title from "../Title";
import About from "../About";
import Portfolio from "../Portfolio";
import Team from "../Team";
import Contact from "../Contact";

function Main() {
	return (
		<>
			<Title />
			<About />
			<Portfolio />
			<Team />
			<Contact />
		</>
	);
}

export default Main;
